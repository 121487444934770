import React, { useEffect, useState } from 'react';
import { Router, RouteComponentProps } from '@reach/router';
import { makeGetRequest } from '@helpers/requests';
import { CARD_PUBLIC, VCARD } from '@helpers/api';
import { useSnackbar } from '@components/common/Snackbar';
import { Card as CardType } from '@models/card';
import Loader from '@components/common/Loader';
import Public from '@components/common/Layouts/Public';
import { navigate } from 'gatsby';
import { useTheme } from '@mui/material';

// Powers redirections / works out what to do with card id
const Card: React.FC<RouteComponentProps<{ id: string }> & { preview?: boolean }> = ({
	id,
	preview,
}) => {
	const [openSnackbar] = useSnackbar();
	const theme = useTheme();
	const [loading, setLoading] = useState<boolean>(true);
	const [card, setCard] = useState<CardType | null>(null);

	useEffect(() => {
		// TODO: Make get request for card data

		let isQrCode = !!id && id?.length !== 24;

		(async () => {
			try {
				if (!id) {
					// TODO: Redirect user away to a more ideal page than a snackbar error.
					openSnackbar('No card was given.', 'error');
					return;
				}

				const { data: cardData } = await makeGetRequest(CARD_PUBLIC(id), {
					preview: preview,
					cardTap: !preview && !isQrCode,
					qrScan: !preview && isQrCode,
				});

				setCard(cardData);
				setLoading(false);
			} catch (error) {
				if (error !== 'cancelled') {
					openSnackbar(
						error?.errorMessage ??
							'An error occurred attempting to retrieve this card.',
						'error'
					);
				}
			}
		})();
	}, []);

	useEffect(() => {
		if (card?.mode === 'setup') navigate(`/setup/${card?._id}`);
		if (card?.mode === 'linkHub') navigate(`/l/${card?.linkHubId}`);
		if (card?.mode === 'externalUrl') navigate(`/external-url/${card.externalUrlId}`);
		if (card?.mode === 'vCard') navigate(`/contact-card/${card.vCardId}`);
	}, [card]);

	return (
		<Public>
			<Loader />
		</Public>
	);
};

const CardRouter: React.FC = () => {
	return (
		<Router>
			<Card path="/c/:id" />
			<Card path="/c/preview/:id" preview />
		</Router>
	);
};

export default CardRouter;
